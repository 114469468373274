<template>
	<div :class="themeClass" class="contBox margin-t-10" v-loading="pageLoading">
		<div class="newPersonbtn-Boxx">
			<div class="newPersonbtn-title font-size18 font-weight700 margin-t-4 padding-b-13">我的岗位</div>
			<!-- <div class="newPersonbtn-title1 ">新增员工</div> -->
		</div>
		<div class="newPersonbtn-Box">
			<el-form :model="addCarForm" :rules="rules" ref="addCarForm" label-width="130px" class="demo-ruleForm">
				<div class="text-center font-size16 margin-b-25">{{pageTitle}}</div>
				<el-form-item label="岗位名称" prop="fPositionName">
					<el-input v-model="addCarForm.fPositionName" placeholder="请输入岗位名称"></el-input>
				</el-form-item>
<!-- 				<el-form-item label="岗位编码" prop="fPositionNumber">
					<el-input v-model="addCarForm.fPositionNumber" placeholder="请输入岗位编码"></el-input>
				</el-form-item> -->
				<el-form-item label="岗位角色" prop="stationType">
					<!-- 	<el-select v-model="addCarForm.stationType" clearable placeholder="请选择岗位角色" @change="handlestationType">
						<el-option v-for="item in stationList" :key="item.fRoleID" :label="item.fRoleName" :value="item.fRoleID">
						</el-option>
					</el-select> -->
					<el-select v-model="addCarForm.stationType" clearable multiple placeholder="请选择岗位角色" @change="handlestationType">
						<el-option v-for="item in stationList" :key="item.fRoleID" :label="item.fRoleName" :value="item.fRoleID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="对应角色提示" v-if="addCarForm.stationType != ''" class="tips">
					<div class="font-size12 font-color-FF9900" v-for="item in stationTip" :key="item.fRoleID">
						{{item.fRoleName}}: {{item.fRemark}}
					</div>
				</el-form-item>
				<el-form-item label="">
					<el-button class="background-color-theme" @click="submitForm('addCarForm')">保存</el-button>
				</el-form-item>
			</el-form>
			<div class="bg-box"></div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			var checkMaximum = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('重量不能为空'));
				}
				setTimeout(() => {
					if (value < 10) {
						callback(new Error('重量必须大于10'));
					} else {
						callback();
					}

				}, 1000);
			};
			return {
				stationTip: [],
				pageLoading: false,
				stationList: [], //岗位角色
				pageTitle: "新增员工信息",
				fTransportToolID: '', //编辑员工的员工id
				carToolList: [],
				addCarForm: {
					fPositionID: null,
					stationType: [], //岗位多选
					fPositionName: '', //岗位姓名
					// fPositionNumber: '', //岗位编码
				},
				rules: {
					fPositionName: [{
						required: true,
						message: '请输入岗位名称',
						trigger: 'blur'
					}],
					fPositionNumber: [{
						required: true,
						message: '请输入岗位编码',
						trigger: 'blur',
					}],
					stationType: [{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}],
				}
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {},
		created() {
			this.pageTitle = this.$route.query.pageType;
			this.getTransportToolType();
		},
		methods: {
			//查询所有启用岗位角色
			getTransportToolType() {
				this.ApiRequestPost('api/mall/sys/role/get-by-userid-role', {}).then(res => {
					console.log('启用岗位角色', res);
					this.stationList = res.obj;
					if (this.pageTitle == "编辑岗位信息") {
						this.getEditPosition();
						//console.log(this.$route.query.fPositionID);

					}
				}, error => {

				});
			},
			//编辑前查询
			getEditPosition() {
				this.pageLoading = true;
				this.ApiRequestPost('/api/mall/sys/position/get', {
					id: this.$route.query.fPositionID
				}).then(res => {
					this.pageLoading = false;
					this.addCarForm.fPositionName = res.obj.fPositionName; //岗位名称
					// this.addCarForm.fPositionNumber = res.obj.fPositionNumber; //岗位编码
					res.obj.fRoleID.forEach(item => {
						this.addCarForm.stationType.push(item.fRoleID); //岗位角色赋值
						this.stationTip.push(item);
					});
					this.addCarForm.frv = res.obj.frv;
				}, error => {
					this.pageLoading = false;
				});
			},
			handlestationType(val) {
				console.log(val)
				console.log(this.stationList)
				var arr1 = [];
				var arr = [];
				for (var i = 0; i < this.stationList.length; i++) {
					for (var j = 0; j < val.length; j++) {
						if (this.stationList[i].fRoleID == val[j]) {
							arr.push(this.stationList[i]);
						}
					}
				}
				arr1 = arr1.concat(arr);
				let obj = {}
				let peon = arr1.reduce((cur, next) => {
					obj[next.fRoleID] ? "" : obj[next.fRoleID] = true && cur.push(next);
					return cur;
				}, []) //设置cur默认类型为数组，并且初始值为空的数组
				this.stationTip = peon


			},
			submitForm(formName) {
				console.log(this.addCarForm, "addCarFormaddCarForm")
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.pageTitle == "编辑岗位信息") {
							this.pageLoading = true;
							this.addCarForm.fPositionID = this.$route.query.fPositionID;
							this.ApiRequestPut('api/mall/sys/position/update-postion-and-role-relationship', {
								// "fPositionNumber": this.addCarForm.fPositionNumber,
								"fPositionName": this.addCarForm.fPositionName,
								"fRoleID": this.addCarForm.stationType,
								"fPositionID": this.$route.query.fPositionID,
								"frv": this.addCarForm.frv,
								"rowStatus": 16,
							}).then(
								result => {
									this.pageLoading = false;
									this.$router.push({
										path: '/PersonalContent/ManagementPosition',
									});
								},
								rej => {
									this.pageLoading = false;
									this.getEditPosition();
								}
							);
						} else if (this.pageTitle == "新增岗位信息") {
							this.pageLoading = true;
							this.ApiRequestPost('api/mall/sys/position/create-postion-and-role-relationship', {
								// "fPositionNumber": this.addCarForm.fPositionNumber,
								"fPositionName": this.addCarForm.fPositionName,
								"fRoleID": this.addCarForm.stationType,
							}).then(
								result => {
									this.pageLoading = false;
									this.$router.push({
										path: '/PersonalContent/ManagementPosition',
									});

								},
								rej => {
									this.pageLoading = false;
									this.resetForm();
								}
							);

						}


					} else {
						//console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm() {
				this.addCarForm = {
					fPositionID: null,
					parentType: '', //父级岗位
					stationType: '', //岗位
					fPositionName: '', //岗位姓名
					// fPositionNumber: '', //岗位编码
					fDetail: true, //是否明细
				};
			}

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}


	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}



	.contBox {
		width: 100%;
		background-color: #FFFFFF;
	}

	.demo-ruleForm {
		width: 462px;
		margin: 0px auto;
		margin-left: 200px;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-title1 {
		width: 110px;
		height: 30px;
		background-color: #0173FE;
		border-radius: 2px;
		color: #FFFFFF;
		line-height: 30px;
		text-align: center;
		// float:right;
	}

	.newPersonbtn-title {
		padding-left: 12px;
		margin-top: 13px;
	}

	.newPersonbtn-Boxx {
		display: flex;
		justify-content: space-between;
		margin-right: 20px;
	}

	.newPersonbtn-Box {}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.newPersonbtn-title {
		width: 73px;
		height: 22px;
		color: #000000;

	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 32px;
	}

	::v-deep .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	::v-deep .el-input__icon {
		line-height: 32px;
	}

	::v-deep .tips {
		.el-form-item__label {
			color: #FF9900;
			font-size: 12px;
		}
	}
</style>
